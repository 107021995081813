@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Playfair+Display:wght@700;800&family=Poppins:wght@400;600&family=Roboto&display=swap");
* {
  font-family: "poppins";
}
.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 25px 0px;
}

.navbar ul {
  display: flex;
  list-style: none;
  gap: 40px;
}

.logo {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.link {
  text-decoration: none;
  font-size: 19px;
  color: #686363;
  transition: all 0.4s ease-in;
}

.link:hover {
  color: rgb(155, 33, 54);
}

.active {
  color: rgb(155, 33, 54);
  font-weight: 600;
}

.hamburger {
  cursor: pointer;
  display: none;
  z-index: 200;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 2.5em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: rgb(59, 59, 59);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.button {
  display: flex;
  /* align-items: center; */
}

.button button {
  color: white;
  font-size: 14px;
  background-color: #dcaaaa;
  border-radius: 50px;
  border: none;
  width: 100px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
}

.button:hover .circle {
  transform: translateX(0);
}

.circle {
  border: 2.5px solid #dcaaaa;
  border-radius: 50%;
  height: 30px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #686363;
  font-weight: 600;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  transform: translateX(-40px);
  z-index: 1;
}

.dropdown {
  display: flex;
  gap: 140px;
  justify-content: space-between;
  align-items: center;
}

.burger {
  position: relative;
  width: 30px;
  height: 20px;
  background: transparent;
  cursor: pointer;
  display: none;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #686363;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 22px;
  left: 5px;
}

.nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 20px;
  font-family: poppins;
  /* margin-left: -200px; */
}

@media (max-width: 1100px) {
  .navbar {
    justify-content: space-between;
    padding: 30px 50px;
  }

  .button {
    display: none;
  }

  .hamburger {
    display: block;
  }
  .nav-items.translate {
    transform: translateY(0%) !important;
  }
  .nav-items {
    transform: translateY(-200%);
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: #f8dcdc;
    color: white;
    width: 100vw;
    top: 0;
    left: 0;
    transition: all 0.5s;
    height: auto;
    margin: 0;
    padding: 20px 0px;
  }
}
