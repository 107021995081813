@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&family=Italiana&family=Klee+One&display=swap");

.aboutpage {
  width: auto;
  min-height: 100vh;
  padding: 10px 50px;
}

body {
  overflow-x: hidden;
}

.box {
  height: 350px;
  width: 90%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: end;
  background-color: #fceeee;
}

.about-image {
  height: 350px;
  width: 700px;
  margin-bottom: -80px;
  object-fit: cover;
}

.hrtag-about {
  margin-top: 130px;
  width: 80%;
}

.story {
  padding: 30px 50px;
  text-align: center;
}

.story-part {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.story-img {
  height: 300px;
  object-fit: cover;
  width: 250px;
  border-radius: 50%;
}

.story h2 {
  font-family: "EB Garamond";
  font-style: italic;
  font-size: 35px;
  opacity: 0.8;
}
.story p {
  font-size: 17px;
  font-family: klee one;
  padding: 0px 80px;
  text-align: left;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  padding: 40px 0px;
}

.gallery img {
  height: 400px;
  width: 300px;
  object-fit: cover;
  opacity: 0.98;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.gallery img:hover {
  transform: scale(1.07);
}

.details-overview {
  width: 80%;
  cursor: pointer;
  font-size: 18px;
  margin: 20px auto;
  padding: 10px 40px;
  border: 1px solid gray;
  text-align: left;
  transition: all 0.3s;
}

details > p {
  color: #8b6a6a;
}

.fade-in {
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 0.5s ease-in forwards; /* Apply animation */
}

.form {
  height: 100vh;
  width: 60%;
  margin: auto;
  margin-top: 80px;
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.test {
  width: 35%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: auto;
  padding: 10px 30px;
  transition: all 0.4s;
  cursor: pointer;
}

.test:hover {
  transform: scale(1.08);
}

.profilepic {
  border-radius: 50%;
}

.name p {
  font-size: 25px;
  font-weight: 800;
}

.feedback {
  margin-top: -0px;
}

.name {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form {
  width: 60%;
  height: auto;
  padding: 50px 40px;
  background-color: #fceeee;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form input {
  width: 50%;
  margin: 10px 0px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
}

.submit {
  cursor: pointer;
  color: white;
  background-color: #8b6a6a;
  font-size: 15px;
  width: 300px;
  font-family: Arial, Helvetica, sans-serif;
}

.message {
  outline: none;
  border: none;
  margin-top: 20px;
  width: 60%;
  padding: 6px 10px;
  height: 150px;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start opacity */
    transform: translateY(5%);
  }
  to {
    opacity: 1; /* End opacity */
    transform: translateY(0%);
  }
}

@media (max-width: 1100px) {
  .aboutpage {
    width: auto;
    min-height: 100vh;
    padding: 10px 30px;
  }

  .form input {
    width: 80%;
  }

  .message {
    width: 85%;
    height: 200px;
  }
  .test {
    width: 80%;
  }
  .name {
    flex-flow: wrap;
    justify-content: center;
    margin: 30px 0px;
  }
  .name p {
    height: 30px;
  }
  .box {
    height: 130px;
    width: 100%;
  }
  .about-image {
    height: 150px;
    width: 300px;
    margin-bottom: -50px;
  }

  .story-part {
    flex-direction: column;
  }
  .story p {
    font-family: klee one;
    padding: 0px 0px;
    width: 100%;
    text-align: left;
  }
  .story {
    padding: 10px 0px;
  }

  .story-img {
    height: 400px;
    width: 300px;
    border-radius: 0%;
  }
}
