@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&family=Italiana&family=Klee+One&display=swap");

.footer {
  background-color: #fceeee;
  width: 90%;
  margin: auto;
  margin-top: 150px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 70px;
}

.details p {
  font-family: "klee one";
  font-size: 17px;
}

.details h3 {
  font-family: "EB Garamond";
  font-size: 22px;
  opacity: 0.8;
  font-style: italic;
}

.details h3::first-letter {
  font-size: 40px;
  font-style: italic;
  font-family: "EB Garamond";
}

.det {
  width: 250px;
  text-align: center;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.socials > a {
  font-size: 24px;
  color: rgb(21, 21, 21);
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s;
}

.socials > a:hover {
  color: #b03b3b;
}

@media (max-width: 1100px) {
  .details {
    gap: 0px;
  }

  .footer {
    background-color: #fceeee;
    width: 90%;
    margin: auto;
    margin-top: 150px;
    height: auto;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
