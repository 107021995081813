@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital@0;1&family=Italiana&family=Klee+One&display=swap");

* {
  font-family: "Klee One";
}
/* body { */
/* background-color: rgb(251, 251, 251);
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */

.maincontainer {
  background-color: #fceeee;
  margin: 40px 90px;
  min-height: 100vh;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.words {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 650px;
  margin: 0px 70px;
  margin-top: -10px;
}

.title {
  font-family: "EB Garamond";
  font-weight: 400;
  font-size: 42px;
  line-height: 160%;
  font-style: italic;
  color: #4e4949;
}

.para {
  color: #8b6a6a;
  margin-top: -5px;
  font-family: "Klee One", cursive;
  font-style: normal;
  font-size: 23px;
}

.button2 {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  width: 250px;
}

.button2 button {
  color: white;
  font-size: 16px;
  font-weight: 400;
  background-color: #e37e7e;
  border-radius: 50px;
  border: none;
  width: 200px;
  height: 40px;
  cursor: pointer;
  z-index: 2;
}

.button2:hover .circle2 {
  transform: translateX(0);
}

.circle2 {
  border: 2.5px solid #e37e7e;
  border-radius: 50%;
  height: 30px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #686363;
  font-weight: 600;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  transform: translateX(-40px);
  z-index: 1;
}

.carousel {
  margin: 50px 70px;
  height: 400px;
  width: 300px;
}
.carousel img {
  display: none;
  height: 400px;
  width: 300px;
}

.carousel .activeimg {
  display: block;
}

.aboutsection {
  height: 80vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  gap: 60px;
  padding: 20px 80px;
}

.about {
  height: 60vh;
  width: 300px;
  background-color: #f1c6c6;
  margin-top: 80px;
  margin-left: -80px;
  object-fit: cover;
}

.img2 {
  margin-left: 80px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60vh;
  width: 350px;
}

.content > p {
  font-family: klee one;
  font-size: 18px;
}

.content > h2 {
  font-size: 30px;
  font-family: "EB Garamond";
  font-style: italic;
}

.content > h2::first-letter {
  font-size: 80px;
}

.aboutcont {
  background-color: #fceeee;
  height: 60vh;
  width: 300px;
}

@media (max-width: 1100px) {
  .maincontainer {
    margin: 40px 35px;
  }
  .title {
    font-size: 30px;
  }
  .words {
    width: 80%;
    text-align: center;
    align-items: center;
  }
  .para {
    font-size: 18px;
  }
  .button2 {
    margin-left: 50px;
  }
  .title-section {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carousel {
    height: 400px;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel img {
    display: none;
    height: 400px;
    width: 95%;
  }

  .aboutsection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    text-align: center;
    height: auto;
    gap: 10px;
    /* margin-top: 100px; */
    padding: 10px 20px;
  }
  .about {
    height: 380px;
    margin: 0;
    width: 230px;
  }
  .content {
    width: 100%;
  }

  .aboutcont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 450px;
  }
  .mobile-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}
